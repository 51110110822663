import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private _toastr: ToastrService) {}

  showSuccess(_title: string, _message: string): void {
    this._toastr.success(_message, _title)
  }

  showError(_title: string, _message: string): void {
    this._toastr.error(_message, _title);
  }

  showInfo(_title: string, _message: string): void {
    this._toastr.info(_message, _title);
  }
} 