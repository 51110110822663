<app-navbar></app-navbar>

<!-- START HEADER -->
<div class="header-landing">
  <div class="container textCenter">
    <img
      class="logorufus u-full-width limit300"
      target="_blank"
      src="../../assets/images/logo-rufus.svg"
    />
    <p class="tagline">
      Open Cloud Timing Solution for Timers and Race Directors
    </p>
  </div>

  <div class="container textCenter">
    <div class="">
      <img
        class="illustration"
        src="../../assets/images/rufus-header-illustration.png"
      />
    </div>

    <p class="textCenter highlight-text">
      Built with some of the latest technologies RUFUS combines a set of
      powerful features with an <strong>intuitive</strong> and
      <strong>easy-to-use</strong> user interface to support your race timing
      from start to finish.
    </p>

    <!-- <div class="">
            <div class="serviceUpdate" style="width: 100%">

                <div class="textCenter">
                    <div>
                    <strong>Important:</strong> RUFUS cloud services will remain offline until further notice. Thank you.
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</div>
<!-- END HEADER -->

<!-- START RUFUS CLOUD -->
<div class="bg02-timecloud">
  <div class="container">
    <div class="row flexvcenter">
      <div class="six columns">
        <h1 class="bold-title">Time Cloud for your timing devices</h1>
        <p class="device-description">
          Connect your timing devices to RUFUS Cloud and
          <strong>time your races LIVE from anywhere in the world.</strong>
        </p>
        <p class="device-description">
          Keep your timing data organized and always available in the Cloud,
          <strong
            >manage your timing sessions and devices like never before.</strong
          >
          <a
          href="https://beta-cloud.runonrufus.com"
          target="_blank"
          class="button-join"
          >Join the closed beta!</a
        >
        </p>
      </div>
      <div class="six columns">
        <img style="width: 100%" src="../assets/images/timecloud.png" />
      </div>
    </div>
    <div class="row flexvcenter">
      <div class="six columns">
        <img
          style="margin-bottom: -10px"
          class="hide-phablet"
          src="../assets/images/we-are-open.png"
        />
      </div>

      <div class="six columns">
        <h3 class="hide-desktop">We are open!</h3>
        <p class="api-text">
          With our Open API you'll be able to connect your own timing devices
          with our Cloud, store your timing sessions and publish race results
          LIVE directly from your timing software!
        </p>
        <a
          href="https://docs.runonrufus.com/"
          target="_blank"
          >Learn more about our public API in our docs</a
        >
      </div>
    </div>
  </div>
</div>
<!-- END RUFUS CLOUD -->

<!-- START RUFUS RACE MANAGER -->
<div
  class="bg03-racemanager"
  style="z-index: 10; position: relative; padding-top: 60px"
>
  <div class="container">
    <div class="row flexvcenter">
      <div class="six columns">
        <h1 class="bold-title">RUFUS Race Manager Web</h1>
        <div class="device-description">
          <p>
            RUFUS RM Web is our new race-day timing
            app. It’s licence free, now and forever.
            <strong>No pricing bullshit.</strong>
          </p>
        </div>
        <a
          href="https://rufus-electron-app-downloads.s3.amazonaws.com/rufus-race-manager-latest.exe"
          target="_blank"
          class="button-join"
          >Download Windows App!</a
        ><br />
      </div>
      <div class="six columns">
        <img class="rm-device" src="../../assets/images/dark-mode-2.png" />
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row textCenter">
      <div class="three columns">
        <img src="../../assets/images/top-grids.svg" /><br />
        <strong>Top of the class grids</strong> for quick and easy access to
        data
      </div>
      <div class="three columns">
        <img src="../../assets/images/no-database.svg" /><br />
        <strong>No external database</strong> engine
      </div>
      <div class="three columns">
        <img src="../../assets/images/multi-race.svg" /><br />
        <strong>Multi-race events.</strong> Splits and segments
      </div>
      <div class="three columns">
        <img src="../../assets/images/advanced-import.svg" /><br />
        <strong>Advanced import features</strong> with any standard formats
      </div>
    </div>
    <div class="row textCenter">
      <div class="three columns">
        <img src="../../assets/images/fully-customizable.svg" /><br />
        Fully <strong>customizable prize givings</strong>
      </div>
      <div class="three columns">
        <img src="../../assets/images/automatic-categories.svg" /><br />
        <strong>Automatic categories</strong> generation
      </div>
      <div class="three columns">
        <img src="../../assets/images/paper-output.svg" /><br />
        <strong>Paper output customizable</strong> in wysiwyg mode
      </div>
      <div class="three columns">
        <img src="../../assets/images/integrated.svg" /><br />
        <strong>Integrated with RUFUS Timing App and the Cloud </strong>
      </div>
    </div>

    <h3 class="interfaces-intro" style="display:none">
      RUFUS RM Web interfaces to all major chip timing systems and RFID readers.
      <strong>No checkpoints or passing limits.</strong>
    </h3>
    <div class="compatibility" style="display:none">
      <img
        class="hide-desktop"
        src="../../assets/images/compatible_devices.png"
        style="width: 100%"
      />
      <!--<div class="coming hide-phablet">...And more coming fast!</div>-->
      <img
        class="hide-phablet"
        src="../../assets/images/devices_desktop.png"
        style="width: 100%"
      />
    </div>
  </div>
</div>
<!-- END RUFUS RACE MANAGER -->

<div class="bg02-pink" style="background-color: white; margin-top: 100px">
  <div class="container">
    <div class="six columns">
      <h1 class="bold-title">RUFUS Timing App</h1>

      <div class="device-description">
        <p>
          Extend your timing capabilities with the RUFUS Timing App for Android
          mobiles. <strong> Time manually or automatically </strong> using
          compatible Zebra RFID sleds RFD8500 and RFD2000.
        </p>

        <p>
          <strong
            >Receive the information seamlessly directly in the finish
            line</strong
          >
          using the RUFUS Race Manager Web cloud devices integration.
        </p>

        <p>
          <strong
            >Ideal for clubs, small races, finish line backup and intermediate
            check points.</strong
          >
        </p>

        <a
          href="https://play.google.com/store/apps/details?id=com.runonrufus.rufustiming&hl=es_419"
          target="_blank"
          ><img src="../assets/images/google_play_button.png" /></a
        ><br />
      </div>
    </div>
    <div class="five offset-by-one columns">
      <div>
        <img style="width: 100%" src="../assets/images/demo-timing-app.gif" />
      </div>
    </div>
  </div>
</div>

<!-- END RUFUS TIMING -->

<!-- START RUFUS MEET MANAGER -->
<div class="wave2"></div>
<div class="bg04-blue">
  <div class="container">
    <h1 class="bold-title textCenter" style="color: #fff">
      RUFUS Meet Manager
    </h1>

    <div class="device-description textCenter">
      <p>
        We love swimming! RUFUS Meet Manager (beta) is our first approach to
        what a swim meets management software should look like. RMM is still not
        connected to the Cloud, but soon it’ll be :)
      </p>
    </div>

    <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
      <mdb-carousel-item>
        <div class="view w-100">
          <img
            class="d-block w-100"
            src="../../assets/images/meet-program.png"
            alt="First slide"
          />
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Create your meet program</h3>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img
            class="d-block w-100"
            src="../../assets/images/timing-settings.png"
            alt="Third slide"
          />
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Config your timing system</h3>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img
            class="d-block w-100"
            src="../../assets/images/run-meet.png"
            alt="Second slide"
          />
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Run the meet</h3>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img
            class="d-block w-100"
            src="../../assets/images/race-control.png"
            alt="Third slide"
          />
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Race control panel</h3>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>

    <div class="container">
      <div class="textCenter">
        <a
          href="https://drive.google.com/file/d/1sZv3Q5Q7a43ZznjjCI5MbhdyYvgDVHHq/view?usp=sharing"
          target="_blank"
          class="button-join"
          >Download now</a
        >
      </div>
    </div>

    <br />

    <div class="textCenter">
      <div class="three columns meet-features">
        <img src="../../assets/images/meet-manager-checks.svg" /><br />
        <div>
          Creation and management of entries, heats, results, records and
          reports
        </div>
      </div>
      <div class="three columns meet-features">
        <img src="../../assets/images/meet-manager-connection.svg" /><br />
        <div>Timing systems interfacing</div>
      </div>
      <div class="three columns meet-features">
        <img src="../../assets/images/meet-manager-videowall.svg" /><br />
        <div>Video wall features</div>
      </div>
      <div class="three columns meet-features">
        <img src="../../assets/images/meet-manager-share.svg" /><br />
        <div>
          Support for competitions created with Splash Meet Manager and Omega
        </div>
      </div>
    </div>
  </div>
</div>

<!-- END RUFUS MEET MANAGER -->

<!-- START STAMP -->
<div class="rufus-bottom">
  <img src="../../assets/images/runonrufus-bottom.png" />

  <!-- START FOOTER -->

  <div class="footer">
    <div class="legal">
      <ul>
        <li><a href="contact">Contact</a></li>
        <li><a href="disclaimer">Disclaimer</a></li>
        <li><a href="privacy">Privacy Policy</a></li>
        <li><a href="legal">Legal Information</a></li>
        <li style="display: none">
          <a href="http://help.runonrufus.com" target="_blank">Help</a>
        </li>
      </ul>
      <div>© RUFUS 2023</div>
    </div>
  </div>
</div>
<!-- END FOOTER -->
