<!-- Primary Page Layout
  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
  <div class="macsha-docs-sidebar"></div>

  <div class="containercontact-margin-left">
  
    <div class="">
      <a href="/"><img style="max-width: 400px" src="../../assets/images/logo-rufus-inner.svg" /></a>
    </div>
      
    <div class="" style="margin-top: 5%">
      <h1>You have questions. <br />We can answer them.</h1>
      <h5>We are ready to solve any doubt you have. Contact us today!</h5>
      <br />
    </div>


    <!-- Contact form  -->
    <form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
        <div class="six columns" >

          <div class="input-group">
            <label for="fullName">Name *</label>
            <input formControlName="fullName" class="u-full-width" type="text" placeholder="John Doe" id="fullName" name="fullName" required>
            <div class="error-msg" *ngIf="!nameInput.valid && (nameInput.dirty || nameInput.touched)">
              {{getNameInputError()}}
            </div>
          </div>  

          <br />

          <div class="input-group">
            <label for="email">Email *</label>
            <input formControlName="email" class="u-full-width" type="text" placeholder="yourname@yourcompany.com" id="email" name="email" required>
            <div class="error-msg" *ngIf="!emailInput.valid && (emailInput.dirty || emailInput.touched)">
              {{getEmailInputError()}}
            </div>
          </div>  

          <br />

          <div class="input-group">
            <label>Subject *</label>
            <select formControlName="subject" id="subject" name="subject" placeholder="Choose one" class="u-full-width">
              <option value="" disabled>Choose one</option>
              <option value="Feedback">Feedback</option>
              <option value="Report a bug">Report a bug</option>
              <option value="Feature request">Feature request</option>
              <option value="Sales">Sales</option>
            </select>
            <div class="error-msg" *ngIf="!subjectInput.valid && (subjectInput.dirty || subjectInput.touched)">
              {{getSubjectInputError()}}
            </div>
          </div> 

          <br />

          <div class="input-group ">
            <label for="message">Message *</label>
            <textarea formControlName="message" style="min-height: 300px" class="u-full-width" placeholder="Write your inquiry…" id="message" name="message" required></textarea>
            <div class="error-msg" *ngIf="!messageInput.valid && (messageInput.dirty || messageInput.touched)">
              {{getMessageInputError()}}
            </div>
          </div>  

          <div>* Required</div> 
          <br />
          <div>By clicking "Submit", you confirm that you read and agree to our <a href="privacy" target="_blank">privacy policy</a>.</div>

          <button class="button-submit" type="submit" [disabled]="!contactForm.valid || clicked">
            Submit
          </button>
          
        </div>

    </form>
    <!-- Contact form  -->

  </div>





  <div class="footer">
    <div class="u-pull-right legal">
        <ul>
            <li><a href="/">Home</a></li> 
            <li><a href="contact">Contact</a></li>
            <li><a href="disclaimer">Disclaimer</a></li>
            <li><a href="privacy">Privacy Policy</a></li>
            <li><a href="legal">Legal Information</a></li>
            <li style="display: none"><a href="http://help.runonrufus.com" target="_blank">Help</a></li>
        </ul>      
    </div>    
</div>

<!-- End Document
  –––––––––––––––––––––––––––––––––––––––––––––––––– -->