<header class="header">
  <ul class="menu">
    <li class="menu-item">
      <a href="https://blog.runonrufus.com/" target="_blank">Blog </a>
    </li>
    <li class="menu-item">
      <a href="">🛒 Stores</a>
      <ul class="sub-menu">
        <li class="menu-item"><a href="https://store.runonrufus.com/" target="_blank">🌎 Global</a></li>
        <li class="menu-item"><a href="https://store.runonrufus.com/" target="_blank">🇪🇺 Emea</a></li>
        <li class="menu-item"><a href="https://www.macsha.co.uk/shop/" target="_blank">🇬🇧 UK</a></li>
        <li class="menu-item"><a href="https://loja.runonrufus.com/" target="_blank">🇧🇷 Brasil</a></li>
        <li class="menu-item"><a href="https://adrenalinetime.com.ar/shop/" target="_blank">🇦🇷 Latam</a></li>
      </ul>
    </li>
    <li class="menu-item">
      <a href="https://docs.runonrufus.com/" target="_blank">🤓 Api docs </a>
    </li>
    <li class="menu-item">
      <a href="https://beta-cloud.runonrufus.com/" target="_blank">🔥 Cloud (beta) </a>
    </li>
  </ul>
</header>
