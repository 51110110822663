 <!-- Primary Page Layout
  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
<div class="macsha-docs-sidebar"></div>
    <div class="containerlegal-margin-left">

    <div class="">
      <a href="/"><img style="max-width: 400px" src="../../assets/images/logo-rufus-inner.svg" /></a>
    </div>
   
    <div class="">
        <div class="eight one-half columns" style="margin-top: 5%">
            <h1>Disclaimer</h1>

            <p>Last updated: August, 2021</p>

            <p>The information contained on https://www.runonrufus.com website (the "Service") is for general information purposes only.</p>

            <p>Tungsteno OÜ assumes no responsibility for errors or omissions in the contents on the Service.</p>

            <p>In no event shall Tungsteno OÜ be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. Tungsteno OÜ reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.</p>

            <p>Tungsteno OÜ does not warrant that the Service is free of viruses or other harmful components.</p>

            <h2>External links disclaimer</h2>

            <p>https://www.runonrufus.com website may contain links to external websites that are not provided or maintained by or in any way affiliated with Tungsteno OÜ</p>

            <p>Please note that the Tungsteno OÜ does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
        </div>
    </div>
</div>

<div class="footer">
    <div class="u-pull-right legal">
        <ul>
            <li><a href="/">Home</a></li> 
            <li><a href="contact">Contact</a></li>
            <li><a href="disclaimer">Disclaimer</a></li>
            <li><a href="privacy">Privacy Policy</a></li>
            <li><a href="legal">Legal Information</a></li>
            <li style="display: none"><a href="http://help.runonrufus.com" target="_blank">Help</a></li>
        </ul>      
    </div>    
</div>

<!-- End Document
  –––––––––––––––––––––––––––––––––––––––––––––––––– -->