import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Landing main components
import {ContentComponent} from "./content/content.component";
import {ContactComponent} from "./contact/contact.component";
import {LegalComponent} from "./legal/legal.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {DisclaimerComponent} from "./disclaimer/disclaimer.component";
import {NotfoundComponent} from "./notfound/notfound.component";

const routes: Routes = [ 
  { path: '', component: ContentComponent }, 
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class routing { }