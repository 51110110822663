<div class="container">
    <div>
    
    <div style="text-align: center;">
      <div><img src="../../assets/images/404-bg.jpg" style="width: 100%; max-width: 700px"></div>
      <div style="font-size: 20px">Page not found. <br />
    Just a pup taking a nap.</div>
    <a style="font-size: 20px" href="javascript:history.back()">Go back</a>

</div>
