import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from '../services/toast.service';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  clicked = false; // Handles the button state

  contactForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [ Validators.required ]),
    email: new FormControl('', [ Validators.email, Validators.required ]),
    subject: new FormControl('', [ Validators.required ]),
    message: new FormControl('', [ Validators.required ]),
  });

  constructor(
    private _toast: ToastService,
    private _http: HttpClient) { }

  get nameInput() { return this.contactForm.get('fullName'); }
  get emailInput() { return this.contactForm.get('email'); }
  get subjectInput() { return this.contactForm.get('subject'); }
  get messageInput() { return this.contactForm.get('message'); }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return 'Please enter a valid email address';
    }
    if (this.emailInput.hasError('required')) {
      return 'An email is required';
    }
    return null;
  }

  getNameInputError() {
    if (this.nameInput.hasError('required')) {
      return 'A name is required';
    }
    return null;
  }

  getSubjectInputError() {
    if (this.subjectInput.hasError('required')) {
      return 'A subject is required';
    }
    return null;
  }

  getMessageInputError() {
    if (this.messageInput.hasError('required')) {
      return 'A message is required';
    }
    return null;
  }

  sendMessage(){

    var url = "https://vy2f2ruxr9.execute-api.us-east-1.amazonaws.com/api/sendmail";
    this.clicked = true; 

    try {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Headers', 'Content-Type');

      let data = {
          'email': this.emailInput.value,
          'name': this.nameInput.value,
          'subject' : this.subjectInput.value,
          'message' : this.messageInput.value,
      };

      this._http.post<any>(url, data, {headers}).subscribe(data => {
        
      })

      this._toast.showSuccess('Message Sent!', "We'll contact you asap. Thanks.");
      this.clicked = false; 

      this.contactForm.get('fullName').setValue('');
      this.contactForm.get('email').setValue('');
      this.contactForm.get('subject').setValue('');
      this.contactForm.get('message').setValue('');


    } catch (error) {
      this._toast.showError('Unavailable!', error.toString() + "Email service is under maitenance, please contact via info@runonrufus.com");
      this.clicked = false; 
    }
  }

}
